document.addEventListener('DOMContentLoaded', () => {

	const knowlageBaseBlocks = document.querySelectorAll('.knowledge-base')
	if (knowlageBaseBlocks)
		knowlageBaseBlocks.forEach(block => {
			const buttons = block.querySelectorAll(
				'.product-category__buttons .product-category__button'
			);
			const masterButtons = block.querySelectorAll(`.product-category__masterButton`)

			masterButtons.forEach(masterButton => {
				masterButton.addEventListener('click', () => {
					changeMasterKnowledgeBase(masterButton, block)
				})
			});

			const loadMore = block.querySelector('.blog-archive__load')

			if (loadMore)
				loadMore.addEventListener('click', () => {
					insertPosts(block.getAttribute('data-active'), block)
					if (parseInt(block.getAttribute('data-' + block.getAttribute('data-active') + '-pagination')) + 1 < parseInt(block.getAttribute('data-' + block.getAttribute('data-active') + '-max'))) {
						loadMore.style.display = 'block'
					} else {
						loadMore.style.display = 'none'
					}
				})
			// buttons.forEach((button) => {
			// 	button.addEventListener('click', () => {
			// 		if (button.classList.contains('product-category__button--active')) {
			// 			return;
			// 		}
			// 		var filter = button.classList.contains('product-category__button--category') ? "category" : "usage";
			// 		var term = button.getAttribute('data-slug') == 'all' ? "all" : button.getAttribute('data-' + filter);
			// 		changeCardsKnowledgeCenter(filter, term, block)
			// 	});
			// });
		});
});
const changeMasterKnowledgeBase = (button, block) => {
	var filter = button.getAttribute('data-filter');
	const masterButtons = block.querySelectorAll(`.product-category__masterButton`)

	masterButtons.forEach(masterButton => {
		masterButton.getAttribute('data-filter') == filter ? masterButton.classList.add('product-category__masterButton--active') : masterButton.classList.remove('product-category__masterButton--active');
	});
	changeCardsKnowledgeCenter(filter, 'all', block)




	// const buttons = block.querySelectorAll(
	// 	'.product-category__buttons .product-category__button'
	// );

	// var count = 0;
	// buttons.forEach(button => {
	// 	button.classList.remove('product-category__button--active')

	// 	if (button.getAttribute('data-slug') == 'all') {
	// 		button.classList.add('product-category__button--active')
	// 		button.style.display = "block"
	// 	} else {
	// 		if (button.classList.contains('product-category__button--' + filter)) {
	// 			button.style.display = "block"
	// 			count++;
	// 		} else {
	// 			button.style.display = "none"
	// 		}
	// 	}
	// });

	// block.document.querySelector('.product-category__buttons__bottomLayer').style.display = count ? "block" : "none"
}

const changeCardsKnowledgeCenter = (postType, term, block) => {
	var cards = block.querySelectorAll('.card--blog');
	cards.forEach((card) => {
		card.style.display = 'none';
	});


	var pagination = parseInt(block.getAttribute('data-' + postType + '-pagination'))

	if (!pagination) {
		insertPosts(postType, block)
	}

	const cardsToActivate = block.querySelectorAll('.card--blog[data-postType="' + postType + '"')
	cardsToActivate.forEach((card) => {
		card.style.display = 'block'
	})

	var loadMore = block.querySelector('.blog-archive__load')
	if (parseInt(block.getAttribute('data-' + postType + '-pagination')) < parseInt(block.getAttribute('data-' + postType + '-max'))) {
		loadMore.style.display = 'block'
	} else {
		loadMore.style.display = 'none'
	}

	// if (term == 'all') {

	// } else {
	// 	cards.forEach(card => {
	// 		card.style.display = 'none';
	// 	});
	// 	cards.forEach((card) => {
	// 		if (card.hasAttribute('data-' + postType)) {
	// 			card.style.display = 'block';
	// 		} else {
	// 			card.style.display = 'none';
	// 		}
	// 	});
	// }

	// var activeButton = block.querySelector(
	// 	'.product-category__button--active'
	// );
	// activeButton.classList.remove('product-category__button--active');
	// if (term == 'all') {
	// 	var button = block.querySelector('.product-category__button[data-slug="all"]')
	// } else {
	// 	var button = block.querySelector('.product-category__button[data-' + postType + '="' + term + '"]')
	// }
	// button.classList.add('product-category__button--active');
}

const insertPosts = async (postType, block) => {
	const template = document.querySelector('#blog_card');
	const cards_container = block.querySelector('.blog-archive__cards');
	const pagination = parseInt(block.getAttribute('data-' + postType + '-pagination')) + 1
	// const activeButton = block.querySelector('product-category__button--active')

	var slug = '';
	// if (activeButton.getAttribute('data-slug') == 'all') {
	// 	slug = 'all'
	// } else {
	// 	slug = activeButton.getAttribute('data-'.postType)
	// }
	slug = 'all'

	cards_container.classList.add('blog-archive--loading');
	var posts = await getPosts(slug, postType, pagination);
	if (posts['error']) {
		cards_container.classList.remove('blog-archive--loading');
		return;
	}
	var postsData = posts['posts']
	// cards_container.innerHTML = '';
	postsData.forEach((post) => {
		var card_clone = template.content.cloneNode(true);
		var a = card_clone.querySelector('a');
		a.setAttribute('data-postType', postType)
		a.setAttribute('href', post['link']['url']);
		a.classList.add('card--blog')
		var img = card_clone.querySelector('img');
		img.setAttribute('src', post['image']['url'] ? post['image']['url'] : "");
		img.setAttribute('alt', post['image']['alt'] ? post['image']['alt'] : "");
		var title = card_clone.querySelector('span');
		title.innerHTML = post['title'];
		var hover = card_clone.querySelector('.card__hoverExcerpt')
		hover.innerHTML = post['text']
		cards_container.appendChild(card_clone);
	});

	cards_container.classList.remove('blog-archive--loading');
	block.setAttribute('data-' + postType + '-pagination', pagination)
	block.setAttribute('data-active', postType)
	if (!block.hasAttribute('data-' + postType + '-max')) {
		block.setAttribute('data-' + postType + '-max', posts['m'])
	}

}

const getPosts = async (slug, postType, pagination) => {
	// return $.ajax({
	// 	type: 'post',
	// 	dataType: 'json',
	// 	url: luxon_globals.ajax_url,
	// 	data: {
	// 		pagination: pagination,
	// 		postType: postType,
	// 		categorySlug: slug,
	// 		action: 'get_posts_with_category',
	// 		_ajax_nonce: luxon_globals.nonce,
	// 	},
	// });


	let req = {
		pagination: pagination,
		postType: postType,
		categorySlug: slug,
		action: 'get_posts_with_category',
		_ajax_nonce: luxon_globals.nonce,
	};
	return fetch(luxon_globals.ajax_url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body:  new URLSearchParams(req).toString()
	})
		.then(response => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			return response.json();
		});

};