// document.addEventListener('DOMContentLoaded', () => {
// 	const getSectionParent = (node) => {
// 		while (true) {
// 			if (node.parentNode.tagName == 'SECTION') {
// 				return node.parentNode;
// 			}
// 			if (node.parentNode.tagName == 'BODY') {
// 				return node;
// 			}
// 			node = node.parentNode;
// 		}
// 	};

// 	gsap.registerPlugin(ScrollTrigger);

// 	const fadeups = document.querySelectorAll('.fade-up');

// 	fadeups.forEach((fadeup) => {
// 		// gsap
// 		// 	.timeline({
// 		// 		scrollTrigger: {
// 		// 			trigger: getSectionParent(fadeup),
// 		// 			start: 'top 90%',
// 		// 			end: 'bottom 0%',
// 		// 		},
// 		// 	})
// 		// 	.fromTo(
// 		// 		fadeup,
// 		// 		{ transformOrigin: 'b', autoAlpha: 0, y: 50 },
// 		// 		{
// 		// 			transformOrigin: 'b',
// 		// 			autoAlpha: 1,
// 		// 			y: 0,
// 		// 			delay: 0.1,
// 		// 			duration: 0.8,
// 		// 		}
// 		// 	);
// 	});
// 	const fadelefts = document.querySelectorAll('.fade-left');

// 	fadelefts.forEach((fadeleft) => {
// 		// gsap
// 		// 	.timeline({
// 		// 		scrollTrigger: {
// 		// 			trigger: getSectionParent(fadeleft),
// 		// 			start: 'top 75%',
// 		// 			end: 'bottom 0%',
// 		// 		},
// 		// 	})
// 		// 	.fromTo(
// 		// 		fadeleft,
// 		// 		{ autoAlpha: 0, y: 0, x: 50 },
// 		// 		{ autoAlpha: 1, y: 0, x: 0, delay: 0.1, duration: 0.8 }
// 		// 	);
// 	});
// 	const faderights = document.querySelectorAll('.fade-right');

// 	faderights.forEach((faderight) => {
// 		// gsap
// 		// 	.timeline({
// 		// 		scrollTrigger: {
// 		// 			trigger: getSectionParent(faderight),
// 		// 			start: 'top 75%',
// 		// 			end: 'bottom 0%',
// 		// 		},
// 		// 	})
// 		// 	.fromTo(
// 		// 		faderight,
// 		// 		{ autoAlpha: 0, x: -50 },
// 		// 		{ autoAlpha: 1, x: 0, delay: 0.1, duration: 0.8 }
// 		// 	);
// 	});

// 	const gtkuItems = document.querySelectorAll('.getToKnowUs__item');
// 	const lines = document.querySelectorAll('.workshop__line');
// 	const items = [...gtkuItems, ...lines];
// 	items.forEach((item) => {
// 		gsap
// 			.timeline({
// 				scrollTrigger: {
// 					trigger: item,
// 					start: '-700px',
// 					end: 'bottom 0%',
// 				},
// 			})
// 			.fromTo(
// 				item,
// 				{ autoAlpha: 0, y: -50 },
// 				{ autoAlpha: 1, y: 0, duration: 0.8 }
// 			);
// 	});
// });
