// document.addEventListener('DOMContentLoaded',()=>{
//   const link = document.querySelector('.header__navigation .menu-item-has-children');
//   const megamenu = document.querySelector('.megamenu');

//   link.addEventListener('mouseenter', function() {
//     megamenu.classList.add('megamenu--visible');
//     link.classList.add('hover')
//   });

//   megamenu.addEventListener('mouseleave', function() {
//     megamenu.classList.remove('megamenu--visible');
//     link.classList.remove('hover')
//   });
// })