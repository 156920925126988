document.addEventListener('DOMContentLoaded', () => {

	const productCategoryBlocks = document.querySelectorAll('.blog-archive:not(.knowledge-base)')
	if (productCategoryBlocks)
		productCategoryBlocks.forEach(block => {
			const buttons = block.querySelectorAll(
				'.product-category__buttons .product-category__button'
			);
			const masterButtons = block.querySelectorAll(`.product-category__masterButton`)

			masterButtons.forEach(masterButton => {
				masterButton.addEventListener('click', () => {
					changeMasterBlockArchive(masterButton, block)
				})
			});

			buttons.forEach((button) => {
				button.addEventListener('click', () => {
					if (button.classList.contains('product-category__button--active')) {
						return;
					}
					var filter = button.classList.contains('product-category__button--category') ? "category" : "usage";
					var term = button.getAttribute('data-slug') == 'all' ? "all" : button.getAttribute('data-' + filter);
					changeCardsBlockArchive(filter, term, block)
				});
			});
		});

});
const changeMasterBlockArchive = (button, block) => {
	var filter = button.getAttribute('data-filter');
	const masterButtons = block.querySelectorAll(`.product-category__masterButton`)

	masterButtons.forEach(masterButton => {
		masterButton.getAttribute('data-filter') == filter ? masterButton.classList.add('product-category__masterButton--active') : masterButton.classList.remove('product-category__masterButton--active');
	});

	const buttons = block.querySelectorAll(
		'.product-category__buttons .product-category__button'
	);

	buttons.forEach(button => {
		button.classList.remove('product-category__button--active')

		if (button.getAttribute('data-slug') == 'all') {
			button.classList.add('product-category__button--active')
			button.style.display = "block"
		} else {
			if (button.classList.contains('product-category__button--' + filter)) {
				button.style.display = "block"
			} else {
				button.style.display = "none"
			}
		}
	});
	changeCardsBlockArchive('category', 'all', block)
}

const changeCardsBlockArchive = (filter, term, block) => {
	const cards = block.querySelectorAll('.card--blog');

	if (term == 'all') {
		cards.forEach((card) => {
				card.style.display = 'block';
		});
	} else {
		cards.forEach(card => {
			card.style.display = 'none';
		});
		cards.forEach((card) => {
			if (card.getAttribute('data-' + filter).split('|').includes(term)) {
				card.style.display = 'block';
			} else {
				card.style.display = 'none';
			}
		});
	}

	var activeButton = block.querySelector(
		'.product-category__button--active'
	);
	activeButton.classList.remove('product-category__button--active');
	if (term == 'all') {
		var button = block.querySelector('.product-category__button[data-slug="all"]')
	} else {
		var button = block.querySelector('.product-category__button[data-' + filter + '="' + term + '"]')
	}
	button.classList.add('product-category__button--active');
}